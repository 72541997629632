import React from 'react'
import Toppage from './Members/Toppage'
import Body from './Members/Body'

const Mainpage = () => {
  return (
    <div className=' bg-greennude '>
        <Toppage/>
        <Body/>
    </div>
  )
}

export default Mainpage