export const Project=[
    {
        Title:"Jlayout",
        Funtion:" A Tech Agency",
        Info:" Jlayout is a cutting-edge tech agency that specializes in creating innovative digital solutions. With a focus on sleek design , Jlayout transforms ideas into powerful, user-friendly applications that drive business success",
        Tech:["React","Tailwind","Valtio","fly.io"],
        Website:"https://jlayout.name.ng/",
        Github:"https://github.com/Jonahjohnzon/Jlayout",
        image:"/Image/jl.png",
        id:0,
        side:"justify-center phone:justify-start",
        content:0,
        contents:null,
        text:' text-center phone:text-right',
        sides:"justify-between phone:justify-end",
    },
    {
        Title:"VidForum",
        Funtion:" A Tech Forum",
        Info:" VidForum is an online community dedicated to tech enthusiasts. It focuses on programming, cybersecurity, computers, IT, networking, AI, and other technology-related topics. Users can join discussions.",
        Tech:["React","Nextjs","Tailwind","Valtio","Express","MongoDB", "Tiptap","fly.io"],
        Website:"https://vidplus.com.ng",
        Github:"https://github.com/Jonahjohnzon/vidforum",
        image:"/Image/Vidforum.png",
        id:1,
        side:"justify-center phone:justify-end ",
        content:null,
        contents:0,
        text:'text-center phone:text-left',
        sides:"justify-between phone:justify-start",
    },
    { 
            Title:"JUNK YARD",
            Funtion:" Chat Application",
            Info:" Junkyard is a platform designed for spontaneous and anonymous video or text chat. It allows users to connect with strangers from around the world for casual conversations.",
            Tech:["React","Tailwind","Redux","Expressjs","MongoDB","Socket.io","WebRTC"],
            Website:"https://junkyard.com.ng/",
            Github:"https://github.com/Jonahjohnzon/Junkyard-chatapp",
            image:"/Image/junkyard.png",
            id:2,
            side:" justify-center phone:justify-start",
            content:0,
            text:"text-center phone:text-right",
            contents:null,
            sides:"justify-between phone:justify-end",
        },
    {
        Title:"Glosoap Website",
        Funtion:"Client Website",
        Info:" GloSoap is the soap that makes your hands glow! The glow lets kids see where they have soap and where they need to scrub more.",
        Tech:["React","Tailwind","Node js","Express","Mongo DB"],
        Website:"https://glosoap.netlify.app/",
        Github:"https://github.com/Jonahjohnzon/glosoap",
        image:"/Image/glosoap.png",
        id:3,
        side:"justify-center phone:justify-end",
        content:null,
        contents:0,
        text:'text-center phone:text-left',
        sides:"justify-between phone:justify-start"
    }
 
]
export const Mobile=[
    {
        Title:"Heritage App",
        Funtion:"Loan Mobile Application Demo",
        Info:" An android mobile app for loan application. users are able to store users data and login and out of the application.",
        Tech:["Dart","Flutter","Node js","Express","Mongo DB"],
        Download:"http://heritageloans.ml",
        Github:"https://github.com/Jonahjohnzon/Bank",
        image:"/Image/heritages.png",
        flexact:"row-reverse",
        Website:"https://drive.google.com/file/d/1d1g5Q9-WkRHat55aCfPqWSStd-z2ka_z/view?usp=share_link"
    }
]